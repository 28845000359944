import axios from 'axios'

axios.defaults.baseURL = process.env.VUE_APP_BACKEND_URL;

axios.interceptors.request.use(
  async (config) => {
    let token = localStorage.getItem('token');

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`
      };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;
    
    if (error?.response?.status === 401 && !config?.sent) {
      config.set = true;

      if (error?.response?.data?.message === 'Invalid/expired token') {
        location.href = '/login'
        return;
      }
    }

    return Promise.reject(error);
  }
)

export default axios;
